import { PriceNew, StrikePriceNew } from 'components/PriceNew/PriceNew';
import SponsoredProductSigning from 'components/Product/SponsoredProductSigning/SponsoredProductSigning';
import SliderEmbla from 'components/Slider/SliderEmbla/SliderEmbla';
import { useFlagship } from 'hooks/criteo/useFlagship';
import { useElementViewed } from 'hooks/useElementViewed';
import useProductCard from 'hooks/useProductCard';
import useSigning from 'hooks/useSigning';
import { FlagshipPlacement } from 'types/Criteo';
import { Product } from 'types/Product';
import { Image, Link, OptionalLink, ProductCardTitle, Rating } from 'ui';
import { cn } from 'utils/cn';

type FlagshipProductProps = {
  onClick: (product: Product) => void;
  onView: (product: Product) => void;
  product: Product;
};

const FlagshipProduct = ({ onClick, onView, product }: FlagshipProductProps) => {
  const { mapProductCardSimpleProps } = useProductCard();
  const { href, image, price, rating, strikePrice, title } = mapProductCardSimpleProps({ product });
  const { ref } = useElementViewed(() => onView(product));
  const handleOnClick = () => onClick(product);

  return (
    <div ref={ref} className="grid w-full grid-cols-3 gap-2 px-4 py-3 sm:px-12 md:px-16">
      {image && (
        <Link href={href} onClick={handleOnClick} className="relative col-span-1 h-[136px] md:h-[176px]">
          <Image className="object-contain" src={image.src} alt={image.alt} fill />
        </Link>
      )}

      <div className="col-span-2 flex flex-col justify-center gap-1 md:gap-3">
        <Rating>{rating.value}</Rating>
        <ProductCardTitle href={href} type="small" onClick={handleOnClick}>
          {title}
        </ProductCardTitle>
        <div className="flex items-center gap-2.5">
          <PriceNew>{price}</PriceNew>
          {!!strikePrice && <StrikePriceNew>{strikePrice}</StrikePriceNew>}
        </div>
      </div>
    </div>
  );
};

type FlagshipProps = {
  className?: string;
  placement: FlagshipPlacement;
};

const Flagship = ({ className, placement }: FlagshipProps) => {
  const {
    mappedPlacements: flagshipPlacements,
    onClickFlagship,
    onClickFlagshipProduct,
    onViewFlagship,
    onViewFlagshipProduct,
  } = useFlagship([placement]);
  const flagshipPlacement = flagshipPlacements[0];
  const { items: products, legalInfo, rendering } = flagshipPlacement;
  const { backgroundImage, link } = rendering;
  const { getSponsoredSigning } = useSigning();
  const signing = getSponsoredSigning(legalInfo);
  const { ref } = useElementViewed(() => onViewFlagship(placement));

  return (
    <div
      ref={ref}
      className={cn(
        'relative box-content flex flex-col rounded border border-accent-40 md:grid md:h-[200px] md:grid-cols-5',
        className,
      )}
    >
      <SponsoredProductSigning
        signing={signing}
        className="absolute right-0 top-0 z-base rounded-br-none rounded-tl-none"
      />

      <div className="relative w-full md:col-span-2 md:h-[200px] md:w-auto">
        <OptionalLink href={link?.href} newTab={link?.newTab} onClick={() => onClickFlagship(placement)}>
          <Image
            className="rounded-t object-cover md:hidden"
            src={backgroundImage.mobile.url}
            alt={backgroundImage.mobile.alt}
            fill
          />
          <Image
            className="hidden rounded-l object-cover md:block"
            src={backgroundImage.desktop.url}
            alt={backgroundImage.desktop.alt}
            fill
          />
        </OptionalLink>
      </div>

      <SliderEmbla
        className="h-[160px] md:col-span-3 md:h-[200px]"
        showArrows={{
          xs: true,
        }}
        arrowPosition="CENTER"
        slidesToShow={{
          xs: 1,
        }}
      >
        {products?.map((product) => (
          <FlagshipProduct
            key={product.code}
            product={product}
            onView={onViewFlagshipProduct}
            onClick={onClickFlagshipProduct}
          />
        ))}
      </SliderEmbla>
    </div>
  );
};

export default Flagship;
